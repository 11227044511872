.home {
  :global {
    .content {
      max-width: 80%;
      margin: auto;
      .section {
        padding: 20px 0px;
      }
    }
  }
}